<template>
  <footer
    class="text-sm sm:text-base transition bg-neutral-50 dark:bg-neutral-950 text-neutral-700 dark:text-neutral-300"
  >
    <div
      class="mx-auto max-w-7xl overflow-hidden pt-12 pb-24 sm:pb-12 flex flex-col space-y-6 sm:space-y-8 px-4"
    >
      <nav class="flex flex-wrap justify-center" aria-label="Footer">
        <div
          v-for="item in navigationfooter.main"
          :key="item.name"
          class="px-4 py-2"
        >
          <TheNuxtLink
            :to="item.href"
            :target="item.target"
            class="px-2 py-1 rounded-md hover:dark:text-neutral-200 active:dark:text-neutral-100 hover:dark:bg-neutral-800 active:dark:bg-neutral-700 hover:bg-neutral-200 active:bg-neutral-300 hover:text-neutral-800 active:text-neutral-900"
            >{{ item.name }}</TheNuxtLink
          >
        </div>
      </nav>
      <div class="flex flex-wrap justify-center items-center gap-4">
        <a
          v-for="item in navigationfooter.social"
          :key="item.name"
          :href="item.href"
          target="_blank"
          class="flex flex-wrap items-center justify-center h-8 sm:h-10 w-8 sm:w-10 p-1 rounded-md cursor-pointer hover:dark:text-neutral-100 active:dark:text-white hover:dark:bg-neutral-800 active:dark:bg-neutral-700 hover:bg-neutral-200 active:bg-neutral-300 hover:text-neutral-900 active:text-black"
        >
          <span class="sr-only">{{ item.name }}</span>
          <component v-if="item.icon" :is="item.icon" />
        </a>
      </div>
      <p class="text-center">&copy; {{ new Date().getFullYear() }} NamiComi</p>
      <!--{{ $route.params.locale }}-->
    </div>
  </footer>
</template>

<script setup lang="ts">
import {
  IconBrandDiscord,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-vue";

const nuxtApp = useNuxtApp();
const route = useRoute();
const translate = nuxtApp.$i18n.global.t;

const navigationfooter = computed(() => ({
  main: [
    {
      name: translate("components.navFooter.about"),
      href: `/${route.params.locale}/about`,
      target: "_self",
    },
    //{ name: 'Careers', href: `/${ this.$route.params.locale }/careers` },
    {
      name: translate("components.navFooter.terms"),
      href: `/${route.params.locale}/terms`,
      target: "_self",
    },
    {
      name: translate("components.navFooter.help"),
      href: `https://help.namicomi.com/hc/${route.params.locale}`,
      target: "_blank",
    },
    {
      name: translate("components.navFooter.apps"),
      href: "https://namicomi.app",
      target: "_blank",
    },
  ],
  social: [
    {
      name: "Discord",
      href: "https://nami.moe/discord",
      icon: IconBrandDiscord,
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/NamiComi/",
      icon: IconBrandFacebook,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/officialnamicomi/",
      icon: IconBrandInstagram,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/NamiComi",
      icon: IconBrandTwitter,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/namicomi",
      icon: IconBrandLinkedin,
    },
  ],
}));
</script>
